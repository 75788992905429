import { Button } from "@mantine/core";
import { CellContext } from "@tanstack/react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Screening } from "@/types/screeners";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons/faArrowsRotate";
import { useCallback } from "react";
import { useReprocessScreenerSessionQuery } from "@/hooks/queries/screenings.queries";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";

interface ReprocessCellProps {
  info: CellContext<Screening, void>;
}

export const ReprocessCell = ({ info }: ReprocessCellProps) => {
  const { sessionId } = info.row.original;
  const { mutate } = useReprocessScreenerSessionQuery();
  const track = useTrackAnalytics();

  const handleReprocess = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      mutate(sessionId);
    },
    [mutate, sessionId],
  );
  const rowCount = info.table.getRowCount();
  return (
    <Button
      variant="transparent"
      onClick={(e) => {
        track("Screener Reprocessed", {
          session_id: sessionId,
        });
        if (rowCount > 1) {
          handleReprocess(e);
        }
      }}
      className={rowCount === 1 ? "invisible" : "uppercase"}
    >
      <div className="flex items-center gap-2">
        Reprocess
        <FontAwesomeIcon icon={faArrowsRotate} />
      </div>
    </Button>
  );
};
