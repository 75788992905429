import { Checkbox, Image, Tooltip } from "@mantine/core";
import type {
  CellContext,
  Column,
  ColumnDef,
  Row,
  Table,
} from "@tanstack/react-table";
import { createColumnHelper } from "@tanstack/table-core";
import get from "lodash/get";
import Link from "next/link";
import { type CSSProperties } from "react";

import ActionMenu from "@/components/table/actionMenu";
import { NOT_ASSIGNED } from "@/components/filters";
import {
  BadgeCell,
  CompetencyCell,
  LocationNameCell,
  MultiBadgesCell,
  ReprocessCell,
  ShareLinkCell,
  UnviewedIndicatorText,
} from "@/components/table/cells";
import { AssignUserCell } from "@/components/table/cells/assign-user-cell";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import { type DefaultFilter } from "@/hooks/use-filter-state.hook";
import { type ApiColumn } from "@/types/api";
import {
  type Competency,
  type Screener,
  type Screening,
} from "@/types/screeners";
import {
  type ScreenerLocation,
  ScreenerLocationStatus,
} from "@/types/screener-location";
import type { User, UserListUser } from "@/types/user";
import { type Nullable } from "@/types/utils";
import { locationHasCompletedSessions, truncateString } from "@/utils/helpers";
import { borderColorCodes, rowColorCodes } from "@/utils/ui";
import { LocationCell } from "@/components/table/cells/location-cell";

const customFilterFn = <TableData,>(
  row: Row<TableData>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
) => {
  if (filterValue.length === 0) {
    return true;
  }

  const cellValue: Nullable<string> = row.getValue(columnId);

  // For competency columns, only return true if the value includes "High"
  if (columnId.startsWith("competencies")) {
    const competency = cellValue as unknown as Competency;
    return typeof competency === "object" && competency?.score === "High";
  }
  if (columnId.startsWith("isVisibleOnPdf")) {
    return cellValue !== null && filterValue.includes(cellValue);
  }
  if (columnId === "location") {
    const locations = row.getValue(columnId) as string[];
    return locations?.some((location) =>
      filterValue.includes(location.toLowerCase()),
    );
  }

  return cellValue !== null && filterValue.includes(cellValue.toLowerCase());
};

const highlightFilterFn = (
  row: Row<Screening>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
) => {
  if (!row.original.highlightColor) {
    return filterValue.includes("none");
  }
  return filterValue.includes(row.original.highlightColor);
};

const highMediumLowSortingFn = (
  rowA: Row<Screening>,
  rowB: Row<Screening>,
  columnId: string,
) => {
  // for sorting specific columns
  const priorityMap = {
    High: 3,
    Medium: 2,
    Low: 1,
  };

  const valueA = rowA.getValue(columnId);
  const valueB = rowB.getValue(columnId);

  const priorityA = priorityMap[valueA as keyof typeof priorityMap] || 0;
  const priorityB = priorityMap[valueB as keyof typeof priorityMap] || 0;
  return priorityA - priorityB;
};

const timeAndWordsSortingFn =
  (fieldName: "totalTimeInSeconds" | "totalWords") =>
  (rowA: Row<Screening>, rowB: Row<Screening>) =>
    rowA.original[fieldName] - rowB.original[fieldName];

const timeAndWordsFilterFn =
  (fieldName: "highTimeSpent" | "highWordCount") =>
  (
    row: Row<Screening>,
    field: string,
    { defaultFilter: filterValue }: DefaultFilter<string[]>,
  ) => {
    // The CheckBoxFilter component naturally outputs its filters as an array.
    // In the case of the time/words filter function there is only one possible
    // value, meaning that if there is anything in the filter array we know to
    // filter on high percentile time spent/word count
    if (filterValue?.length > 0) {
      return !!row.original[fieldName];
    }

    return true;
  };

const companyFitFilterFn = (
  row: Row<Screening>,
  columnId: string,
  { defaultFilter: filterValue }: DefaultFilter<string[]>,
): boolean => {
  const ko = row.original.qualifyingPassRate;
  const overallFit = row.original.overallFit;

  // Return true to keep the row if it matches the filter criteria
  let fitsCriteria = true;
  if (filterValue.includes("high: overall fit") && overallFit !== "High") {
    fitsCriteria = false;
  }
  if (filterValue.includes("100% knockout") && ko !== 100) {
    fitsCriteria = false;
  }
  return fitsCriteria;
};

export const getCommonPinningStyles = <T,>(
  column: Column<T>,
  row?: Row<Screening>,
): CSSProperties => {
  const isPinned = column.getIsPinned();
  // We have to use box shadow rather than border to maintain it with sticky positioning
  const boxShadow = (() => {
    if (!isPinned) {
      return;
    }

    if (column.getIsFirstColumn("left") && row?.original.highlightColor) {
      return `inset 4px 0px 0px 0px ${borderColorCodes[row?.original.highlightColor]}`;
    }

    if (column.getIsLastColumn("left")) {
      return `inset -1px 0px 0px 0px var(--mantine-color-gray-0)`;
    }
  })();

  return {
    boxShadow,
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : 0,
  };
};

export const getJobPinningStyles = (
  column: Column<Screening>,
  row?: Row<Screening>,
): CSSProperties => ({
  ...getCommonPinningStyles(column, row),
  backgroundColor: row?.original.highlightColor
    ? rowColorCodes[row.original.highlightColor]
    : "white",
});

export const getLocationPinningStyles = (
  column: Column<ScreenerLocation>,
  row?: Row<ScreenerLocation>,
): CSSProperties => ({
  ...getCommonPinningStyles(column),
  backgroundColor:
    !row || locationHasCompletedSessions(row.original) ? "white" : "#F6F6F6",
});

export const getScreenerPinningStyles = (
  column: Column<Screener>,
  row?: Row<Screener>,
): CSSProperties => ({
  ...getCommonPinningStyles(column),
  backgroundColor:
    !row || row.original.status !== ScreenerLocationStatus.Paused
      ? "white"
      : "#F6F6F6",
});

export const columnSizingHandler = <T,>(
  tableHead: HTMLTableCellElement | null,
  table: Table<T>,
  column: Column<T>,
) => {
  // Only update columnSizing if the element's dimensions have actually changed to prevent an infinite loop.
  if (
    tableHead &&
    table.getState().columnSizing[column.id] !==
      tableHead.getBoundingClientRect().width
  ) {
    table.setColumnSizing((prevSizes) => ({
      ...prevSizes,
      [column.id]: tableHead.getBoundingClientRect().width,
    }));
  }
};

const createdAtCell = <
  TableData extends Screening | ScreenerLocation | Screener,
>() => ({
  filterFn: (
    row: Row<TableData>,
    columnId: string,
    {
      after = -Infinity,
      before = Infinity,
      sessionStatus,
    }: { after?: number; before?: number; sessionStatus?: string[] },
  ) => {
    if (Object.hasOwn(row.original, "sessionStatus")) {
      const screening = row.original as Screening;

      // The CheckBoxFilter component naturally outputs its filters as an array.
      // Because the sessionStatus has only one possible value ("completed"),
      // we know that if there is a value in the array we only need to check
      // the row's session status.
      if ((sessionStatus?.length ?? 0) > 0) {
        return screening.sessionStatus === "completed";
      }
    }

    const cellValue = new Date(row.getValue(columnId)).valueOf();
    return cellValue >= after && cellValue <= before;
  },
  cell: (info: CellContext<TableData, string>) => {
    const isPartialSessionStatus =
      // Even though we don't know this is a screening, casting is safe here as we want this to return false for
      // screener locations regardless.
      (info.row.original as Screening)?.sessionStatus === "partial";
    const cellValue = info.getValue();

    return (
      <div
        className={`w-full flex gap-2 justify-start items-center ${
          isPartialSessionStatus ? "text-red-600" : ""
        }`}
      >
        {cellValue
          ? new Date(cellValue).toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })
          : "—"}
        {isPartialSessionStatus && (
          <Image
            alt="This session is incomplete/partial"
            src="/assets/red-x.svg"
          />
        )}
      </div>
    );
  },
});

const textCell = <TData,>(
  info: CellContext<TData, string | number | undefined>,
) => {
  const value = info.getValue();
  const stringValue = value ? String(value) : "";

  return stringValue.length > 50 ? (
    <Tooltip
      label={info.getValue()}
      withArrow
      arrowSize={8}
      c="black"
      color="white"
      w={400}
      className="border border-gray-200 z-[1000]"
    >
      <span className="truncate">{truncateString(stringValue, 50)}</span>
    </Tooltip>
  ) : (
    stringValue || "—"
  );
};

const badgeFilledCell = <TData,>(column: ApiColumn<TData>, suffix?: string) => {
  return (info: CellContext<TData, string | number>) => {
    const value = info.getValue();

    return (
      <BadgeCell fieldName={String(column.fieldName)} filled value={value}>
        {`${value}${suffix || ""}`}
      </BadgeCell>
    );
  };
};

const locationCell = (info: CellContext<Screener, string[]>) => {
  return <LocationCell location={info.getValue()} />;
};

const defaultCell = <TData, TValue>(column: ApiColumn<TData>) => ({
  header: column.displayName,
  accessorKey: column.fieldName,
  filterFn: customFilterFn,
  cell: (info: CellContext<TData, TValue>) => info.getValue() || "—",
});

export const formatScreenerColumns = (
  columns: ApiColumn<Screener>[],
  // Because the column definitions array is consumed by Tanstack Table rather
  // than directly by us, I'm not particularly worried about `any` here. The
  // actual return type would be something like
  // ColumnDef<Screener, string | number | Competency | unknown | ...
  // and even once working with that return type TS takes issue with unknown
  // further down the chain.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<Screener, any>[] => {
  const columnHelper = createColumnHelper<Screener>();

  return columns.map((column) => {
    const { fieldName, displayName: header } = column;

    switch (fieldName) {
      case "jobTitle":
        return columnHelper.accessor(fieldName, {
          // function format here to make eslint happy.
          cell: function JobTitleCell(info: CellContext<Screener, string>) {
            const track = useTrackAnalytics();
            const { id, unviewedSessionCount } = info.row.original;

            return (
              <Link
                href={`/screener/${id}`}
                className="block text-uw-purple w-full"
                onClick={() => {
                  track("Screener Clicked", { screener_id: id });
                }}
              >
                <UnviewedIndicatorText
                  indicatorText={`${unviewedSessionCount}`}
                  primaryText={info.getValue()}
                  showIndicatorText={unviewedSessionCount > 0}
                />
              </Link>
            );
          },
          filterFn: customFilterFn,
          header,
        });
      case "internalName":
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: customFilterFn,
          header,
        });
      case "screenings":
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: customFilterFn,
          header,
        });
      case "status":
        return columnHelper.accessor(fieldName, {
          cell: badgeFilledCell(column),
          filterFn: customFilterFn,
          header,
        });
      case "location":
        return columnHelper.accessor(fieldName, {
          cell: locationCell,
          filterFn: customFilterFn,
          header,
        });
      case "lastSubmissionDate":
        return columnHelper.accessor(fieldName, {
          header,
          ...createdAtCell(),
        });
      case "createdAt":
        return columnHelper.accessor(fieldName, {
          header,
          ...createdAtCell(),
        });
      case "wage":
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: customFilterFn,
          header,
        });
      default:
        return columnHelper.accessor(fieldName, {
          ...defaultCell<Screener, string | number>(column),
        });
    }
  });
};

export const formatScreenerLocationColumns = (
  columns: ApiColumn<ScreenerLocation>[],
  // See explanation in `formatScreenerColumns
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<ScreenerLocation, any>[] =>
  columns.map((column) => {
    const { displayName: header, fieldName } = column;
    const columnHelper = createColumnHelper<ScreenerLocation>();

    switch (fieldName) {
      case "locationName":
        return columnHelper.accessor(fieldName, {
          header,
          filterFn: customFilterFn,
          cell: (info) => <LocationNameCell info={info} />,
        });
      case "jobName":
        return columnHelper.accessor(fieldName, {
          header,
          filterFn: customFilterFn,
        });
      case "assignedTo":
        return columnHelper.accessor(fieldName, {
          header,
          cell: (
            info: CellContext<ScreenerLocation, Nullable<UserListUser>>,
          ) => <AssignUserCell info={info} />,
          filterFn: (
            row: Row<ScreenerLocation>,
            columnId: string,
            { defaultFilter: filterValue }: DefaultFilter<string[]>,
          ) => {
            if (!filterValue || filterValue.length < 1) {
              return true;
            }

            const assignedTo = row.original?.assignedTo;

            if (filterValue.includes(NOT_ASSIGNED) && assignedTo === null) {
              return true;
            }

            return !!(assignedTo && filterValue.includes(assignedTo.id));
          },
        });
      case "lastSubmissionDate":
        return columnHelper.accessor(fieldName, {
          header,
          ...createdAtCell(),
        });
      case "shareLink":
        return columnHelper.accessor(fieldName, {
          header,
          cell: (info) => <ShareLinkCell info={info} />,
        });
      case "status":
        return columnHelper.accessor(fieldName, {
          header,
          filterFn: customFilterFn,
          cell: badgeFilledCell(column),
        });
      default:
        return columnHelper.accessor(fieldName, {
          ...defaultCell<ScreenerLocation, string | number>(column),
        });
    }
  });

export const formatCandidateColumns = (
  columns: ApiColumn<Screening>[],
  user: User | null,
  // See explanation in `formatScreenerColumns
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ColumnDef<Screening, any>[] => {
  const columnHelper = createColumnHelper<Screening>();

  const formattedColumns = columns.map((column) => {
    const { displayName: header, fieldName } = column;

    if (fieldName.startsWith("competencies")) {
      const competencyName = fieldName.slice(fieldName.indexOf(".") + 1);

      return columnHelper.accessor(
        (originalRow) => get(originalRow, ["competencies", competencyName]),
        {
          cell: (info) => (
            <CompetencyCell info={info as CellContext<Screening, Competency>} />
          ),
          filterFn: customFilterFn,
          header,
          id: fieldName.replace(/\s+/g, ""),
          sortingFn: highMediumLowSortingFn,
        },
      ) as ColumnDef<Screening, Competency>;
    }

    if (fieldName.startsWith("isVisibleOnPdf")) {
      const propertyName = fieldName.slice(fieldName.indexOf(".") + 1);

      return columnHelper.accessor(
        (originalRow) => get(originalRow, ["isVisibleOnPdf", propertyName]),
        {
          cell:
            column.type === "multiple_choice"
              ? (info: CellContext<Screening, string>) => {
                  return (
                    <MultiBadgesCell
                      fieldName={fieldName}
                      sessionId={info.row.original.sessionId}
                      values={info.getValue()?.split(",") || []}
                    />
                  );
                }
              : textCell,
          filterFn: customFilterFn,
          header,
          id: fieldName.replace(/\s+/g, ""),
        },
      );
    }

    switch (fieldName) {
      case "candidateName":
        return columnHelper.accessor(fieldName, {
          cell: (info: CellContext<Screening, string>) => {
            return (
              <UnviewedIndicatorText
                indicatorText="NEW"
                primaryText={info.getValue()}
                showIndicatorText={!info.row.original.viewedAt}
              />
            );
          },
          filterFn: (row, columnId, { defaultFilter: filterValue }) =>
            filterValue && !row.original.viewedAt,
          header,
        });
      case "createdAt":
        return columnHelper.accessor(fieldName, {
          header,
          ...createdAtCell(),
        });
      case "qualifyingPassRate":
        return columnHelper.accessor(fieldName, {
          cell: badgeFilledCell(column, "%"),
          filterFn: companyFitFilterFn,
          header,
        });
      case "overallFit":
        return columnHelper.accessor(fieldName, {
          cell: badgeFilledCell(column),
          filterFn: companyFitFilterFn,
          header,
          sortingFn: highMediumLowSortingFn,
        });
      case "words":
      case "totalWords":
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: timeAndWordsFilterFn("highWordCount"),
          header,
          sortingFn: timeAndWordsSortingFn("totalWords"),
        });
      case "time":
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: timeAndWordsFilterFn("highTimeSpent"),
          header,
          sortingFn: timeAndWordsSortingFn("totalTimeInSeconds"),
        });
      default:
        return columnHelper.accessor(fieldName, {
          cell: textCell,
          filterFn: customFilterFn,
          header,
        });
    }
  });

  const actionsColumn = {
    header: "Actions",
    accessorKey: "actions",
    cell: (info: CellContext<Screening, void>) => (
      <div
        className="flex items-center gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <ActionMenu row={info.row.original} />
        {user?.internal && <ReprocessCell info={info} />}
      </div>
    ),
    enableSorting: false,
  };

  const highlightColorColumn = {
    header: "",
    accessorKey: "highlightColor",
    filterFn: highlightFilterFn,
    cell: () => {
      return null;
    },
    isVisible: false,
    enableSorting: false,
  };

  const selectionColumn: ColumnDef<Screening> = {
    id: "selection",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onChange={table.getToggleAllPageRowsSelectedHandler()}
        indeterminate={table.getIsSomePageRowsSelected()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
        onClick={(event) => {
          event.stopPropagation();
        }}
      />
    ),
  };

  return [
    selectionColumn,
    ...formattedColumns,
    actionsColumn,
    highlightColorColumn,
  ];
};
