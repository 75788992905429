import { Badge, Text } from "@mantine/core";
import { forwardRef, type PropsWithChildren } from "react";

import { getBadgeColor } from "@/utils/ui";

interface BadgeCellProps {
  fieldName: string;
  filled?: boolean;
  value?: string | number;
}

export const BadgeCell = forwardRef<
  HTMLDivElement,
  PropsWithChildren<BadgeCellProps>
>(({ children, fieldName, filled = false, value }, ref) => {
  if (value === null || value === undefined || value === "") {
    return null;
  }

  return (
    <Badge
      color={getBadgeColor(value, fieldName)?.background}
      ref={ref}
      size="lg"
      style={{
        padding: "2px 9px",
        width: 72,
      }}
      variant={filled ? "filled" : "outline"}
    >
      <Text
        c={getBadgeColor(value, fieldName)?.text}
        fw={700}
        className="tracking-wide"
        size="xs"
      >
        {children}
      </Text>
    </Badge>
  );
});
