import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-solid-svg-icons/faCopy";
import { Text, Tooltip } from "@mantine/core";
import { useTimeout } from "@mantine/hooks";
import type { CellContext } from "@tanstack/react-table";
import { useCallback, useState } from "react";

import type { ScreenerLocation } from "@/types/screener-location";
import { MANTINE_COLORS } from "@/utils/ui";

interface ShareLinkCellProps {
  info: CellContext<ScreenerLocation, string>;
}

export const ShareLinkCell = ({ info }: ShareLinkCellProps) => {
  const [copied, setCopied] = useState(false);
  const { start } = useTimeout(
    useCallback(() => {
      setCopied(false);
    }, [setCopied]),
    2500,
  );
  const value = info.getValue();

  if (!value) {
    return null;
  }

  return (
    <Tooltip
      arrowPosition="center"
      arrowSize={8}
      classNames={{
        tooltip: "drop-shadow-[0_0_6px_rgba(0,0,0,0.10)]",
      }}
      color={copied ? MANTINE_COLORS.green : "white"}
      label={
        <div className="flex flex-row items-center">
          <Text c={copied ? "white" : "black"} size="sm">
            {copied ? "Copied" : "Copy link"}
          </Text>
          <FontAwesomeIcon
            className="ml-[6px]"
            color={copied ? "white" : MANTINE_COLORS.green}
            icon={copied ? faCheck : faCopy}
          />
        </div>
      }
      withArrow
    >
      <button
        className="decoration-dotted text-uw-purple text-left underline"
        onClick={(e) => {
          e.stopPropagation();

          navigator.clipboard.writeText(value).then(() => {
            setCopied(true);
            start();
          });
        }}
        style={{ width: "100%" }}
      >
        {value}
      </button>
    </Tooltip>
  );
};
