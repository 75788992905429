import { Checkbox, CheckboxGroup } from "@mantine/core";
import { type ColumnFiltersState } from "@tanstack/react-table";

import { useFilterAnalytics } from "@/hooks/analytics.hooks";
import { twMerge } from "tailwind-merge";
import {
  Filter,
  handleSimpleArrayFilterChange,
  useFilterState,
} from "@/hooks/use-filter-state.hook";
import { Dispatch, SetStateAction, useMemo } from "react";
import { Maybe } from "@/types/utils";

const COLORS = [
  {
    color: "Red",
    tailwindClass: "bg-red-400",
  },
  {
    color: "Orange",
    tailwindClass: "bg-orange-400",
  },
  {
    color: "Yellow",
    tailwindClass: "bg-yellow-400",
  },
  {
    color: "Green",
    tailwindClass: "bg-green-400",
  },
  {
    color: "Blue",
    tailwindClass: "bg-sky-400",
  },
  {
    color: "Purple",
    tailwindClass: "bg-purple-400",
  },
] as const;

export const HighlightFilter = ({
  filters,
  setFilters,
}: {
  filters: ColumnFiltersState;
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
}) => {
  const trackFilter = useFilterAnalytics("Highlight");
  const [filterValue, updateFilter] = useFilterState<Maybe<string[]>>(
    filters as Filter[],
    setFilters,
    "highlightColor",
  );

  const handleHighlightChange = useMemo(
    () => handleSimpleArrayFilterChange(updateFilter, filterValue, trackFilter),
    [filterValue, trackFilter, updateFilter],
  );

  return (
    <CheckboxGroup>
      {COLORS.map(({ color, tailwindClass }) => {
        const lowerCaseColor = color.toLowerCase();
        return (
          <Checkbox
            checked={filterValue?.includes(lowerCaseColor)}
            className="my-1"
            key={color}
            label={
              <div className="flex items-center">
                <div
                  className={twMerge(
                    "h-2.5",
                    "w-2.5",
                    "flex",
                    "items-center",
                    "rounded-full",
                    "mr-1",
                    tailwindClass,
                  )}
                />
                <div>{color}</div>
              </div>
            }
            onChange={() => handleHighlightChange(lowerCaseColor)}
            size="sm"
            value={lowerCaseColor}
          />
        );
      })}
      <Checkbox
        checked={filterValue?.includes("none")}
        className="my-1"
        key="none"
        label={
          <div className="flex items-center">
            <div
              className={twMerge(
                "h-2.5",
                "w-2.5",
                "flex",
                "items-center",
                "rounded-full",
                "mr-1",
                "bg-white",
                "border",
                "border-gray-300",
              )}
            />
            <div>Not highlighted</div>
          </div>
        }
        onChange={() => {
          handleHighlightChange("none");
        }}
        size="sm"
        value={""}
      />
    </CheckboxGroup>
  );
};
