import { Text } from "@mantine/core";
import type { CellContext } from "@tanstack/react-table";
import Link from "next/link";

import { UnviewedIndicatorText } from "./unviewed-indicator-text";
import { useTrackAnalytics } from "@/hooks/analytics.hooks";
import type { ScreenerLocation } from "@/types/screener-location";
import { locationHasCompletedSessions } from "@/utils/helpers";

interface JobNameCellProps {
  info: CellContext<ScreenerLocation, string>;
}

export const LocationNameCell = ({ info }: JobNameCellProps) => {
  const row = info.row.original;
  const track = useTrackAnalytics();

  // Avoid linking to locations without any screener sessions as they will 404.
  if (locationHasCompletedSessions(row)) {
    return (
      <Link
        href={row.positionLink}
        className="block w-full"
        onClick={(e) => {
          e.stopPropagation();
          track("Position Clicked", {
            screener_location_id: row.id,
          });
        }}
      >
        <UnviewedIndicatorText
          indicatorText={`${row.unviewedSessionCount}`}
          primaryText={info.getValue()}
          showIndicatorText={row.unviewedSessionCount > 0}
        />
      </Link>
    );
  }

  return <Text size="sm">{info.getValue()}</Text>;
};
