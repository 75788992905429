"use client";

import { Flex, Text } from "@mantine/core";

import { useUserContext } from "@/context/user.context";
import {
  useCompaniesListQuery,
  useCompanyImpersonationMutation,
} from "@/hooks/queries/company.queries";
import { LAYOUT_DIMENSIONS } from "@/utils/ui";

export const ImpersonationBanner = () => {
  const { user } = useUserContext();
  const { data: companies, isLoading } = useCompaniesListQuery();
  const { mutate: changeCompany } = useCompanyImpersonationMutation();

  // START: This block filters out specified companies for listed external users
  // not allowing them to impersonate companies they shouldn't have access to

  // external users to filter out for
  const externalUsers = [
    "daisy@upwage.com",
    "jihan@upwage.com",
    // "your-email@upwage.com", // for testing
  ];
  // companies to filter out for external users
  const companyIds = [
    "c611430f-9184-452b-a803-a4fa2ac85e51", // Compass staging
    "baaa188f-6c17-43fe-b70d-6cfa60475f69", // Compass production
  ];
  const isExternalUser = externalUsers.includes(user?.email || "");
  const filteredCompanies = companies?.filter(
    (company) => !companyIds.includes(company.id),
  );
  // END

  return (
    <Flex
      bg="yellow.2"
      className="flex-row items-center px-[18px] py-[9px] w-full"
      style={{ height: LAYOUT_DIMENSIONS.IMPERSONATION_BANNER_HEIGHT_PX }}
    >
      <Text size="sm">
        {isLoading ? "Loading..." : "Viewing as a member of"}
      </Text>
      {!isLoading && (
        <select
          className="bg-transparent border border-uw-gray-7 font-semibold ml-[8px] rounded-md px-[10px] py-[6px] text-sm"
          defaultValue={user?.companyImpersonation || user?.company.id}
          onChange={(e) => changeCompany(e.target.value)}
        >
          {(isExternalUser ? filteredCompanies : companies)?.map(
            ({ id, name }) => (
              <option className="font-semibold text-xs" key={id} value={id}>
                {name}
              </option>
            ),
          )}
        </select>
      )}
    </Flex>
  );
};
