import { Flex } from "@mantine/core";
import { twMerge } from "tailwind-merge";

interface UnviewedIndicatorTextProps {
  indicatorText: string;
  primaryText: string;
  showIndicatorText: boolean;
}

export const UnviewedIndicatorText = ({
  indicatorText,
  primaryText,
  showIndicatorText,
}: UnviewedIndicatorTextProps) => {
  return (
    <Flex
      className={twMerge("text-uw-purple", showIndicatorText && "font-bold")}
      justify="space-between"
    >
      <span className="mr-3">{primaryText}</span>
      {showIndicatorText && (
        <Flex
          className="bg-[#EFEAFA] px-2 py-0.5 rounded-full text-xs min-w-8"
          justify="center"
        >
          {indicatorText}
        </Flex>
      )}
    </Flex>
  );
};
