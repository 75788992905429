import { faCaretDown } from "@fortawesome/pro-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseButton, Combobox, ComboboxStore, InputBase } from "@mantine/core";
import { forwardRef, type PropsWithChildren, type ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import type { Nullable } from "@/types/utils";
import { stopPropagationWrapper } from "@/utils/ui";

interface ComboboxTargetButtonProps {
  leftSection?: Nullable<ReactNode>;
  onClear?: () => void;
  showClearButton?: boolean;
  store: ComboboxStore;
}

export const ComboboxTargetButton = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ComboboxTargetButtonProps>
>(({ children, leftSection = null, onClear, showClearButton, store }, ref) => {
  return (
    <Combobox.Target ref={ref}>
      <InputBase
        c="gray.5"
        component="button"
        classNames={{
          input: twMerge([
            "border",
            "border-uw-gray-2",
            "rounded",
            "text-sm",
            "focus:border-uw-purple",
          ]),
        }}
        leftSection={leftSection}
        leftSectionPointerEvents="none"
        onClick={stopPropagationWrapper(() => store.toggleDropdown())}
        pointer
        rightSection={
          showClearButton ? (
            <CloseButton
              aria-label="Clear value"
              c="purple.5"
              onClick={() => onClear?.()}
              onMouseDown={(event) => event.preventDefault()}
              size="sm"
            />
          ) : (
            <FontAwesomeIcon className="text-sm" icon={faCaretDown} />
          )
        }
        rightSectionPointerEvents={showClearButton ? "all" : "none"}
        styles={{ input: { color: "var(--mantine-color-gray-5)" } }}
        type="button"
      >
        {children}
      </InputBase>
    </Combobox.Target>
  );
});
