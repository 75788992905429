import type { ColumnFiltersState } from "@tanstack/react-table";
import { type Dispatch, type SetStateAction, useMemo } from "react";

import { DateFilter } from "./date-filter";
import { DateRangeCombobox } from "./date-range-combobox";
import { DateRangeOptions } from "@/types/date-range-filter";
import type { Nullable } from "@/types/utils";

export enum DateRangeFilterTypes {
  CreatedAt,
  DateStarted,
  LastSubmissionDate,
}

interface DateRangeFilterProps {
  dateRange: Nullable<DateRangeOptions>;
  filters: ColumnFiltersState;
  setDateRange: (dateRange: Nullable<DateRangeOptions>) => void;
  setFilters: Dispatch<SetStateAction<ColumnFiltersState>>;
  type: DateRangeFilterTypes;
}

export const DateRangeFilter = ({
  dateRange,
  filters,
  setDateRange,
  setFilters,
  type,
}: DateRangeFilterProps) => {
  const [title, namePrefix, columnName] = useMemo(() => {
    switch (type) {
      case DateRangeFilterTypes.CreatedAt:
        return ["Date created", "Created", "createdAt"];
      case DateRangeFilterTypes.DateStarted:
        return ["Date started", "Started", "createdAt"];
      case DateRangeFilterTypes.LastSubmissionDate:
        return ["Last submission date", "Submitted", "lastSubmissionDate"];
    }
  }, [type]);

  return (
    <>
      <DateRangeCombobox
        analyticsName={title}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      {dateRange === DateRangeOptions.CustomRange && (
        <>
          <DateFilter
            analyticsName={`${title} - after`}
            columnName={columnName}
            name={`${namePrefix} after`}
            filterName="after"
            filters={filters}
            setFilters={setFilters}
          />
          <DateFilter
            analyticsName={`${title} - before`}
            columnName={columnName}
            name={`${namePrefix} before`}
            filterName="before"
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )}
    </>
  );
};
